import { Component, Inject, NgZone, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Exercises, Progress } from 'src/app/shared/models';
import { Student } from '../../students/interfaces/student.interface';
import { StudentProgramInfoHelperService } from '../program-helpers/student-program-info-helper.service';
import { cloneDeep, includes, get, uniq, uniqueId } from 'lodash';
import { EXCLUDED_EXERCISES_TYPE } from '../consts/program-game-consts';
import { MetadataDialogComponent } from 'src/app/shared/dialogs/metadata/metadata.dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ChartJSOptions } from 'src/app/shared/consts/chartjs-options';
import { ChartJSDoughnutOptions } from 'src/app/shared/consts/chartjs-doughnut-options';
import { gameNamesCDH, progressTypes } from 'src/app/shared/consts/global-constants';
import { Chart } from 'chart.js';
import moment from 'moment';
import { FullSessionResultComponent } from 'src/app/shared/dialogs/full-session-result/full-session-result';
import { Theme } from '../../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { GraphTypes } from '../../configuration-pages/content-configurations/components/categories/utils/categories-utils';
import { Category } from '../../configuration-pages/interfaces/global-config.interfaces';

interface StudentResultData {
  student: Student;
  progress: Progress[];
  category: Category;
  themeInfo: Theme;
  completeProgress: Progress[];
  selectedRerun: number;
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'student-results-dialog',
  templateUrl: 'student-results-dialog.component.html',
  styleUrls: ['student-results-dialog.component.scss'],
})
export class StudentResultsDialog implements OnInit {
  constructor(
    private studentInfoHelper: StudentProgramInfoHelperService,
    @Inject(MAT_DIALOG_DATA) public data: StudentResultData,
    public _dialog: MatDialog,
    private _zone: NgZone,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<StudentResultsDialog>,
  ) {}

  public score = 'Score';
  public charts = [];
  public canvasCharts = [];
  public loading = true;
  public rerunOptions: number[] = [];
  public selectedRerun = 0;
  public progressList: Progress[] = [];

  // TODO change this to be dynamic

  public barChartBackgroundColor = '#fdbd4e';
  public doughnutChartBackgroundColor = ['#009ee5', '#ff4778', '#00c3c1', '#ffcd67', '#ff9947'];

  public chartBuilder = {
    // Default graph type for Cognitive Therapy
    bar: () => {
      const options = this.getBarChartTypeOptions();

      const graph = {
        id: uniqueId(),
        options,
        data: {
          labels: this.fillSessionsArray(this.data.category.maxSessions),
          datasets: [
            {
              data: this.buildSessionScoreData(this.progressList),
              label: this.score,
              backgroundColor: this.barChartBackgroundColor,
              borderWidth: 1,
            },
          ],
        },
        type: 'bar',
        width: 890,
        height: 700,
      };

      this.charts.push(graph);
    },

    // Default graph type for Speed Reading
    doughnut: () => {
      const weeklyData = this.buildWeeklyScoreData(this.progressList);

      for (const data of weeklyData) {
        const index = weeklyData.indexOf(data);
        const options = this.getDoughnutChartTypeOptions(index + 1);
        const graph = {
          id: uniqueId(),
          options,
          data: {
            labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
            datasets: [
              {
                data,
                backgroundColor: this.doughnutChartBackgroundColor,
                borderWidth: 1,
              },
            ],
            index,
          },
          type: 'doughnut',
          width: 130,
          height: 270,
        };

        this.charts.push(graph);
      }
    },

    // Default graph type for Reading Exercises
    pie: () => {
      const weeklyData = this.buildWeeklyScoreData(this.progressList);

      for (const data of weeklyData) {
        const index = weeklyData.indexOf(data);
        const options = this.getDoughnutChartTypeOptions(index + 1);
        const graph = {
          id: uniqueId(),
          options,
          data: {
            datasets: [
              {
                data,
                backgroundColor: this.doughnutChartBackgroundColor,
                borderWidth: 1,
              },
            ],
            index,
          },
          type: 'pie',
          width: 120,
          height: 270,
        };

        this.charts.push(graph);
      }
    },
  };

  ngOnInit(): void {
    this.prepareRerun();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  private selectProgress = () => {
    this.progressList = this.data.progress.filter((p) => (p.rerun ?? 0) === this.selectedRerun);
    this.getGraphInfo();
  };

  private prepareRerun = () => {
    const tempProgress: number[] = this.data.progress.map((p) => p.rerun ?? 0);
    const uniqueRerunOptions: number[] = uniq(tempProgress, 'rerun').sort();
    this.rerunOptions = uniqueRerunOptions.reverse();
    this.selectedRerun = this.data.selectedRerun;
    this.selectProgress();
  };

  private waitForElementToExist = (selector) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        subtree: true,
        childList: true,
      });
    });
  };

  public async buildResultsGraphs() {
    for (const chart of this.charts) {
      const wCanvas = (await this.waitForElementToExist('#chart' + chart.id)) as HTMLCanvasElement;
      const wCtx = wCanvas.getContext('2d');

      Object.assign(chart.options, {
        hover: {
          onHover: (e, HTMLElementArray) => {
            wCanvas.style.cursor = HTMLElementArray[0] ? 'pointer' : 'default';
          },
        },
      });

      const canvasChart = new Chart(wCtx, {
        type: chart.type,
        data: chart.data,
        options: chart.options,
      });

      this.canvasCharts.push(canvasChart);
    }
  }

  public fillSessionsArray(maxSessions: number): string[] {
    const array = Array.from({ length: maxSessions }, (e, i: number) => `Session ${i + 1}`);

    return array;
  }

  public getProgressHighestScore(): number {
    const scores = this.progressList.map((p) => Number(this.getTotalScore(p)));

    return Math.max(...scores);
  }

  public getBarChartTypeOptions() {
    const options = Object.assign({}, ChartJSOptions);
    options.title = { display: true, text: this.data.category.name };
    options.scales.yAxes = [
      {
        ticks: { min: 0, max: this.getProgressHighestScore() },
      },
    ];

    return options;
  }

  public getDoughnutChartTypeOptions(week: number) {
    const options = Object.assign({}, ChartJSDoughnutOptions);
    options.title = { display: true, text: 'Week ' + week };

    return options;
  }

  public getGraphInfo() {
    for (const canvasChart of this.canvasCharts) {
      canvasChart.destroy();
    }
    this.canvasCharts = [];
    this.charts = [];

    this.chartBuilder[this.data.category.graphType || 'bar']();
    this.buildResultsGraphs();
  }

  public getTotalScore(progress: Progress): string {
    const metadata = progress.metadata;

    if (typeof metadata.score != 'undefined') {
      return metadata.score.toString();
    } else if (metadata.exercises && !metadata.savedGame) {
      const totalScore = metadata.exercises
        .map((e) => e.score)
        .reduce((accumulatedScore: number, exerciseScore: number) => {
          return accumulatedScore + exerciseScore;
        }, 0);

      return totalScore.toString();
    }
  }

  public buildSessionScoreData(progress: Progress[]) {
    const data = progress.map((p) => {
      return {
        y: this.getTotalScore(p),
        date: moment(new Date(p.creationTime)).format('LLL'),
      };
    });

    return data;
  }

  public buildWeeklyScoreData(progress: Progress[]) {
    const totalWeeks = this.data.category.maxSessions / 5;
    const weekData = Array.from({ length: totalWeeks }, () => []);

    const result = function (y: string, date: string) {
      this.y = y;
      this.date = date;
      this.toString = () => {
        return this.y;
      };
    };

    for (const prog of progress) {
      const week = Math.floor((prog.session - 1) / 5);
      const dayOfTheWeek = (prog.session - 1) % 5;

      weekData[week][dayOfTheWeek] = new result(
        this.getTotalScore(prog),
        moment(new Date(prog.creationTime)).format('LLL'),
      );
    }

    return weekData;
  }

  public returnSession(progSession, tag) {
    return tag === progressTypes.COGNITIVE_THERAPY
      ? progSession
      : `Week ${Math.ceil(progSession / 5)} Day ${((progSession - 1) % 5) + 1}`;
  }

  public filterMetadata(tag: string, exercises) {
    const studentLevel =
      this.studentInfoHelper.getStudentLevel(this.data.student, this.data.themeInfo, this.data.completeProgress) || 0;

    this.progressList.forEach((progress, i) => {
      if (progress.tag === tag) {
        exercises[i] = cloneDeep(progress.metadata.exercises);

        exercises[i] = exercises[i].filter((exercise) => includes(EXCLUDED_EXERCISES_TYPE, exercise.name) === false);
        // Checks if the exercises have less fields than 3 and populate it
        if (Object.keys(exercises[i][0]).length <= 3) {
          exercises[i].forEach((exercise) => {
            exercise.session = this.returnSession(progress.session, tag);
            exercise.lang = this.data.student.language;
            exercise.level = studentLevel > 0 ? `P${studentLevel}` : 'JR';
          });
        }
      }
    });
    return exercises.filter((x) => x);
  }

  public isAssessmentProgress(): boolean {
    return this.data.category.name.toLowerCase() === progressTypes.ASSESSMENT;
  }

  public filterCharts = (query) => {
    return this.canvasCharts.filter((chart) => get(chart, 'canvas.id', '').includes(query));
  };

  public chartClickEvent(event, index) {
    const categoryName = this.data.category.name.toLowerCase();
    const exerciseArray = [];
    const [chartElement] = get(this.filterCharts('chart'), `${[index]}`, {}).getElementsAtEvent(event);
    const weekIndex = get(chartElement, '_chart.config.data.index');

    if (chartElement && !this.isAssessmentProgress()) {
      const sessionMetadata = this.filterMetadata(categoryName, exerciseArray);

      const weekMetadata = sessionMetadata.slice(weekIndex * 5, weekIndex * 5 + 5);
      const index = chartElement._index;

      // if we have more than one chart we need to separete the progress in chunks of 5 days per week
      const metadata = this.canvasCharts.length > 1 ? [].concat(...weekMetadata) : sessionMetadata[index];

      if (metadata) {
        this._zone.run(() => {
          this._dialog.open(MetadataDialogComponent, {
            width: 'auto',
            data: {
              metadata,
            },
          });
        });
      }
    }
  }

  public openFullResultChart() {
    const progress = this.progressList.filter((c) => c.tag === this.data.category.name.toLowerCase());
    const scoresArray = this.getFullResultsArray(progress);

    this._dialog.open(FullSessionResultComponent, {
      width: '1100px',
      maxHeight: '650px',
      data: {
        scoresArray,
      },
      panelClass: 'modal-border',
    });
  }

  public getFilteredExercises(exercises: Exercises[]) {
    return exercises.filter((exercise) => includes(EXCLUDED_EXERCISES_TYPE, exercise.name) === false && exercise.name);
  }

  public getExerciseName(name: string): string {
    const gameList = this.data.category.games;
    const game = gameList.find((g) => name.includes(g.type));

    return game ? game.type : name;
  }

  public getExercisesColum(progress: Progress[]) {
    const exerciseNames = progress
      .map((p) => {
        const filteredExercises = this.getFilteredExercises(p.metadata.exercises);

        return filteredExercises.map(
          (e, index) =>
            this.getExerciseName(e.name) + (this.exerciseHasDuplicate(e.name, filteredExercises) === true ? index : ''),
        );
      })
      .reduce((a, b) => {
        // if the category graph is not a bar type that means the scores are separated by weeks,
        // so we need to keep iterating until we fill the array with all the category exercises

        if (this.data.category.graphType === GraphTypes.bar) {
          return a;
        } else {
          return a.length < this.data.category.games.length ? uniq(a.concat(b)) : a;
        }
      });

    return ['session', 'level', ...exerciseNames, 'total'];
  }

  public exerciseHasDuplicate(name: string, filteredExercises: Exercises[]): boolean {
    // check if a exercise is played more than once on the session

    const exercise = filteredExercises.filter((e) => e.name === name) || [];

    return exercise.length > 1;
  }

  public getFullResultsArray(progress: Progress[]) {
    const fullProgressChart = progress.map((p) => {
      const filteredExercises = this.getFilteredExercises(p.metadata.exercises);
      const colums = filteredExercises.map(
        (e, index) =>
          this.getExerciseName(e.name) + (this.exerciseHasDuplicate(e.name, filteredExercises) === true ? index : ''),
      );
      const completeColums = ['session', 'level', ...colums, 'total'];

      const exerciseScores = filteredExercises
        .map((e, index) => {
          const name =
            this.getExerciseName(e.name) + (this.exerciseHasDuplicate(e.name, filteredExercises) === true ? index : '');
          return {
            [name]: e.score,
            session: p.session,
            total: p.metadata.exercises.reduce((a, b) => a + b.score, 0),
            level: e.level,
          };
        })
        .reduce((a, b) => Object.assign(a, b));

      return {
        colums: completeColums,
        exerciseScores,
      };
    });
    console.log(fullProgressChart);
    return fullProgressChart;
  }

  swapNamesCDH(name) {
    return gameNamesCDH[name];
  }
}
