<app-main-layout>
  <div class="inner-content">
    <h2>Purchasing Student Bundles</h2>

    <p class="description">
      Neuralign is a 40+ hours web-based reading intervention which implements
      strategies based on the latest research to stimulate the auditory-visual
      sensory system. Once you have purchased your student bundle you can assign
      a student to the Neuralign program.
    </p>

    <mat-form-field appearance="outline">
      <mat-label>Bundle</mat-label>
      <mat-select
        placeholder="Bundle"
        [(ngModel)]="selectedBundle"
      >
        <mat-option
          (click)="quantity = 1"
          *ngFor="let bundle of bundles"
          [value]="bundle"
        >
          {{ bundle?.nickname }}
          <span *ngIf="getBundleInterval(bundle)">
            ( {{ bundle.interval ? bundle.interval : 'Month' }} )
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div
      class="selectedBundle"
      *ngIf="selectedBundle"
    >
      <h3>{{ selectedBundle?.title }}</h3>
      <p class="price">
        &#36;{{
          selectedBundle.unit_amount
            ? selectedBundle.unit_amount / 100
            : selectedBundle?.price
        }}
        {{ selectedBundle.currency.toUpperCase() }}
        <span *ngIf="getBundleInterval(bundle)"
          >/
          {{
            selectedBundle.interval ? selectedBundle.interval : 'Month'
          }}</span
        >
      </p>
      <p class="bundle-info">
        <small *ngIf="couponCodeResponse">
          (${{ getDiscountAmount() }} coupon discount)
          <br />
        </small>
        <small *ngIf="selectedBundle.studentsIncluded">
          {{ selectedBundle.studentsIncluded }} student
          {{ pluralizeCredits(selectedBundle.studentsIncluded) }}
        </small>
        <small *ngIf="selectedBundle.metadata?.tokens">
          {{ selectedBundle.metadata?.tokens }} student
          {{ pluralizeCredits(selectedBundle.metadata?.tokens) }}
        </small>
      </p>
    </div>

    <div class="two-column">
      <mat-form-field
        appearance="outline"
        class="mr-1"
      >
        <mat-label>Quantity</mat-label>
        <input
          matInput
          required
          type="number"
          placeholder="Quantity"
          [(ngModel)]="quantity"
          [disabled]="
            selectedBundle?.isFirstPurchaseBundle ||
            selectedBundle?.metadata?.first_purchase === 'yes'
          "
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Coupon Code</mat-label>
        <input
          matInput
          placeholder="Coupon Code"
          [(ngModel)]="couponCode"
          (blur)="checkCoupon()"
        />
      </mat-form-field>
    </div>

    <div class="one-column">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          required
          placeholder="Name"
          [(ngModel)]="name"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input
          matInput
          required
          placeholder="E-mail"
          [(ngModel)]="email"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Referred by</mat-label>
        <input
          matInput
          placeholder="Referred by"
          [(ngModel)]="referral"
        />
      </mat-form-field>
    </div>

    <div
      *ngIf="savedCards"
      class="cardList"
      [ngClass]="{ open: openSavedCards }"
    >
      <div
        *ngFor="let card of savedCards"
        class="cardRow"
      >
        <label class="radioButton">
          <input
            type="radio"
            [(ngModel)]="selectedCard"
            [value]="card.id"
          />
          <div class="checkmark">
            <fa-icon [icon]="checkIcon"></fa-icon>
          </div>
        </label>
        <fa-icon
          class="cardBrand"
          [icon]="getCardIcon(card.brand)"
        ></fa-icon>
        **** **** ****
        <b>{{ card.last4 }}</b> - Expiry:
        {{ (card.exp_month > 9 ? '0' : '') + card.exp_month }}/{{
          card.exp_year
        }}
      </div>
    </div>

    <form id="payment-form">
      <input
        type="hidden"
        name="token"
      />
      <div
        class="group"
        [ngClass]="{ hide: openSavedCards }"
      >
        <label class="cardInfo">
          <div
            id="card-number-element"
            class="field"
            [ngClass]="{
              invalid:
                !validInput.cardNumber.empty && !validInput.cardNumber.complete,
              complete:
                !validInput.cardNumber.empty && validInput.cardNumber.complete
            }"
          ></div>
        </label>
        <div class="creditCardsAccepted">
          We accept
          <fa-icon
            class="ccIcon"
            [icon]="visa"
          ></fa-icon>
          <fa-icon
            class="ccIcon"
            [icon]="master"
          ></fa-icon>
          <fa-icon
            class="ccIcon"
            [icon]="amex"
          ></fa-icon>

          <img
            class="stripeIcon"
            src="assets/img/Powered by Stripe.svg"
          />
        </div>
        <label class="cardInfo">
          <div
            id="card-expiry-element"
            class="field"
            [ngClass]="{
              invalid:
                !validInput.cardExpiry.empty && !validInput.cardExpiry.complete,
              complete:
                !validInput.cardExpiry.empty && validInput.cardExpiry.complete
            }"
          ></div>
        </label>
        <label class="cardInfo">
          <div
            id="card-cvc-element"
            class="field"
            [ngClass]="{
              invalid:
                !validInput.cardCvc.empty && !validInput.cardCvc.complete,
              complete: !validInput.cardCvc.empty && validInput.cardCvc.complete
            }"
          ></div>
        </label>
        <input
          type="hidden"
          name="name"
          [(ngModel)]="name"
        />
      </div>

      <div class="review">
        <h4>Order Summary</h4>
        <p class="submit">
          By submitting your order, you agree with our
          <a
            routerLink="/terms-of-service"
            target="_blank"
            >Terms of Service</a
          >.
        </p>

        <div *ngIf="selectedBundle">
          <div
            class="mt-3 subscriptionAgreement"
            *ngIf="isSubscriptionBundle(selectedBundle)"
          >
            I understand I am enrolling in a
            {{ (getBundleInterval(selectedBundle) || 'month') + 'ly' }}
            subscription program and I authorize Neuralign, via electronic
            signature, to charge my credit card account the
            {{ getBundleInterval(selectedBundle) || 'month' + 'ly' }} amount
            each {{ getBundleInterval(selectedBundle) || 'month' }}, unless I
            cancel. <br />
            <mat-checkbox
              class="mt-3"
              [(ngModel)]="subscriptionAgreement"
              [ngModelOptions]="{ standalone: true }"
            >
              I have read and agree to the terms above</mat-checkbox
            >
          </div>

          <div
            class="mt-3 subscriptionAgreement"
            *ngIf="isInstallmentPaymentBundle(selectedBundle)"
          >
            I understand I am enrolling in a
            {{ selectedBundle.interval + 'ly' }} payment program, and I
            authorize Neuralign, via electronic signature, to charge my credit
            card account the
            {{ getBundleInterval(selectedBundle) + 'ly' }} amount each
            {{ getBundleInterval(selectedBundle) }} for the next
            {{ selectedBundle.subscriptionPeriod }}
            {{ getBundleInterval(selectedBundle) + 's' }}. <br />
            <mat-checkbox
              class="mt-3"
              [(ngModel)]="subscriptionAgreement"
              [ngModelOptions]="{ standalone: true }"
            >
              I have read and agree to the terms above</mat-checkbox
            >
          </div>
        </div>
        <br />

        <div
          class="summary"
          *ngIf="selectedBundle"
        >
          <p>
            <span
              >{{ quantity }}x {{ selectedBundle?.title }}
              <span *ngIf="!getBundleInterval(bundle)">Bundle/s </span>:
            </span>

            <span
              >${{
                selectedBundle.unit_amount
                  ? selectedBundle.unit_amount / 100
                  : selectedBundle?.price
              }}
              {{ selectedBundle.currency.toUpperCase() }}
              <span
                *ngIf="
                  isInstallmentPaymentBundle(selectedBundle) ||
                  isSubscriptionBundle(selectedBundle)
                "
                >/{{
                  selectedBundle.interval ? selectedBundle.interval : 'Month'
                }}</span
              >
            </span>
          </p>
          <p *ngIf="tax?.percentage">
            {{ tax?.inclusive === true ? 'Tax Included' : 'Tax not Included' }}
            <span> {{ tax?.percentage }}% </span>
          </p>
          <p *ngIf="selectedBundle?.metadata?.tokens">
            <small
              >({{ selectedBundle?.metadata?.tokens * quantity }} student
              bundle/s)</small
            >
          </p>
          <p *ngIf="couponCodeResponse && couponCodeResponse.isValid">
            Discounts:
            <span
              >${{ getDiscountAmount() }} {{ selectedBundle.currency }}</span
            >
          </p>
          <p class="order-total">
            Order Total:
            <span
              >${{ getOrderTotal() }}
              {{ selectedBundle.currency.toUpperCase() }}</span
            >
          </p>
        </div>
      </div>

      <button
        class="col-sm-12 col-lg-2 offset-lg-5"
        *ngIf="!validInput.sending"
        [disabled]="
          isFormSubmited ||
          (!subscriptionAgreement && getBundleInterval(selectedBundle))
        "
        type="submit"
        [ngClass]="{
          invalid:
            !(
              (validInput.cardNumber.complete &&
                validInput.cardExpiry.complete &&
                validInput.cardCvc.complete &&
                !openSavedCards) ||
              (selectedCard && openSavedCards) ||
              isFormSubmited
            ) ||
            (!subscriptionAgreement && getBundleInterval(selectedBundle))
        }"
      >
        SUBMIT
      </button>
      <button
        class="col-sm-12 col-lg-4 offset-lg-4 invalid"
        *ngIf="validInput.sending"
        type="submit"
        disabled
      >
        SUBMITING PAYMENT
        <fa-icon
          [icon]="loading"
          [spin]="true"
          class="text-white"
        ></fa-icon>
      </button>
    </form>
  </div>
</app-main-layout>
