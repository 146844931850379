// import { environment } from '@lsworks/data';

import { environment } from 'src/environments/environment';

const gtagURL = `https://www.googletagmanager.com/gtag/js?id=${environment.GOOGLE.ANALYTICS_TAG}`;

const gtagScriptContent = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'ANALYTICS_TAG_PLACEHOLDER');
  gtag('config', 'ADWORDS_TAG_PLACEHOLDER');
  `;

export function initializeGoogleAnalytics() {
  // load google tag manager
  const script = document.createElement('script');
  script.src = gtagURL;
  document.head.appendChild(script);

  // load gtag
  const gtag = document.createElement('script');
  script.type = 'text/javscript';
  const blob = new Blob([gtagScriptContent], { type: 'text/javascript' });
  const url = URL.createObjectURL(blob);
  gtag.src = url;
  document.head.appendChild(gtag);
}
