import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ProgramInfo } from '../students/interfaces/program-info.interface';
import { ProgramResults } from '../students/interfaces/program-results.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { get, includes, upperFirst, isUndefined } from 'lodash';
import { RoleService } from 'src/app/services/roles/role.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { HttpParams } from '@angular/common/http';
import { defaultCategoryTypes, progressTypes } from 'src/app/shared/consts/global-constants';
import { StudentProgramInfoHelperService } from './program-helpers/student-program-info-helper.service';
import { ProgramHelper } from './program-helpers/program-helper';
import moment from 'moment';
import { Program, Progress, Token, User } from 'src/app/shared/models';
import {
  faArrowCircleLeft,
  faArrowLeft,
  faArrowRight,
  IconDefinition,
  faPenToSquare,
  faLock,
  faQuestionCircle,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { LockTypes, classicThemeLabel } from './program-game.const';
import { GameState, UnityMessages } from 'src/app/shared/components/game/game.constants';
import { GameStundentDTO, ProgressData, QueryData } from 'src/app/shared/components/game/game.interface';
import { classic } from 'src/app/shared/consts/themeLabels';
import { savedGameDialogComponent } from 'src/app/shared/components/game/saved-game-dialog/saved-game-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentResultsDialog } from './student-results-dialog/student-results-dialog.component';
import { Student } from '../students/interfaces/student.interface';
import { GameComponent } from 'src/app/shared/components/game/game.component';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { colors } from '../students/student-profile/calendar-dialog/consts/calendar.consts';
import { LevelOverrideDialogComponent } from './level-override-dialog/level-override-dialog.component';
import {
  StudentLevel,
  VIDEO,
  clientBlockedAccessMessage,
  clientFailedPaymentBlockedAccessMessage,
  fiveDaysMenu,
  programName,
} from './consts/program-game-consts';
import { CategoryInfoDialog } from './category-info-dialog/category-info-dialog';
import { BeforeYouStartComponent } from 'src/app/shared/components/before-you-start/before-you-start.component';
import { DaysMenu, ImageDaysStates } from './utils/program-utils';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import {
  Language,
  Theme,
} from '../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import {
  ContainerTypes,
  GraphTypes,
} from '../configuration-pages/content-configurations/components/categories/utils/categories-utils';
import { Category, CategoryType, UnlockCondition } from '../configuration-pages/interfaces/global-config.interfaces';
import { ProgramCompletionDialog } from './program-completion-dialog/program-completion-dialog';
import { uniq } from 'lodash';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';
import { VideoLessonContentFEM } from '../courses/services/courses/courses.service.models';
import { VideoCategoryComponent } from './video-category/video-category.component';
import { VideoContent } from './program-interfaces/program-interfaces';
import { VideoCategoryService } from './video-category-service/video-category.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

interface StudentPageInfo {
  isAdminUser: boolean;
  studentBelongsToUser: boolean;
}

interface ClientAccess {
  shouldBlockAccess: boolean;
  blockType: LockTypes | undefined;
}

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
})
export class ProgramComponent implements OnInit {
  public dayStateArray: string[] = [];
  public fiveDaysMenu: DaysMenu;
  public showFiveDaysMenu = false;
  public allowUnityClose = false;

  public programName = '';
  public studentName = '';
  public studentId = '';
  public studentProgram: Program;
  public studentTheme: string;
  public student: Student;
  public programInfo: ProgramInfo;
  public studentResults: ProgramResults;
  public selectedLesson: Category;
  public progress: Progress[];
  public allProgress: Progress[] = [];
  public assessmentProgress: Progress[] = [];
  public programList: Program[] = [];
  public languageList: Language[] = [];
  public categoryList: Category[] = [];

  public coreCategoryList: Category[] = [];
  public extrasCategoryList: Category[] = [];

  public waitByPass = false;
  public byPassCounter = 0;
  public user: User;
  public tokens: Token[];
  public beforeYouStart = false;
  public themeInfo: Theme;
  public showGame = false;
  public loadingInfo = true;
  public gameLoading = true;

  public isPatron = false;
  public isPortalAdmin = false;
  public isOrgAdmin = false;
  public isOrgManager = false;
  public isB2C = false;

  public showVideo = false;
  public videoContent: VideoContent;
  public videoSection: VideoLessonContentFEM;

  public allowCompleteAtHome = false;

  public savedGameProgress = [];

  public queryData: QueryData;
  public gameStudentDTO: GameStundentDTO;

  public readonly back: IconDefinition = faArrowCircleLeft;

  public view: CalendarView = CalendarView.Month;

  public viewDate: Date = new Date();

  public events: CalendarEvent[] = [];
  public calendarLabels = [];
  private isDebugMode = false;
  private selectedRerun = 0;
  private tokenHigherRun = 0;
  private rerunOptions = [];

  public readonly arrowRight: IconDefinition = faArrowRight;
  public readonly arrowLeft: IconDefinition = faArrowLeft;
  public readonly edit: IconDefinition = faPenToSquare;
  public readonly lock: IconDefinition = faLock;
  public readonly moreInfo: IconDefinition = faQuestionCircle;
  public readonly circle: IconDefinition = faCircle;

  @ViewChild(GameComponent, { static: false }) gameComponentRef: GameComponent;

  public conditionToUnlockMessages = {
    session: (category: Category) => this.getLockedSessionMessage(category.unlockCondition),
    interval: (category: Category) => this.getNextSessionMessage(category),
    maxSessions: (category: Category) => `You already completed all the ${category.name} sessions`,
  };

  constructor(
    public translate: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _rest: RestAPIService,
    private _auth: AuthService,
    private _roles: RoleService,
    private _logger: LoggerService,
    public studentInfoHelper: StudentProgramInfoHelperService,
    public programHelper: ProgramHelper,
    private router: Router,
    public dialog: MatDialog,
    protected _snackBar: MatSnackBar,
    private globalConfigHelper: GlobalConfigurationHelper,
    private studentHelper: StudentHelperService,
    private videoCategoryService: VideoCategoryService,
  ) {}

  async ngOnInit() {
    this.programName = this._activatedRoute.snapshot.paramMap.get('programName');
    this.studentName = this._activatedRoute.snapshot.paramMap.get('name');
    this.studentId = this._activatedRoute.snapshot.paramMap.get('studentId');
    this.isDebugMode = Boolean(this._activatedRoute.snapshot.queryParams.debug);

    await this.getProgramList();
    await this.getCategoryList();
    await this.getStudentPageInfo();
    await this._getStudentInfo(this.studentId);
    await this.getThemeInformation(this.studentTheme);
    await this._getProgramInfo();
    await this.getUserRoles();
    await this._getStudentProgress(this.studentId);
    await this.getLanguages();
    this.fillStudentAgenda();
    this.studentProgram = this.programList.find((p) => p.name === programName);
    this.loadingInfo = false;
    await this.checkForProgramCompletion();
  }

  // Skip a video exercise
  public async nextVideoExercise() {
    if (this.videoContent) {
      this.loadingInfo = true;
      this.showVideo = false;

      const progressData = this.videoContent.progressData;
      const lesson = this.videoContent.content;
      const section = lesson.contentList.find((s) => s.id === this.videoSection.id);

      if (section) {
        const index = lesson.contentList.indexOf(section);
        await this.videoCategoryService.saveVideoCategoryProgress(progressData, lesson, section.id);

        if (index === lesson.contentList.length - 1) {
          this.endSession();
        } else {
          const nextSection = lesson.contentList[index + 1];
          this.videoSection = nextSection;
          this.videoSection.isLast = index + 1 >= lesson.contentList.length - 1;
          this.loadingInfo = false;
          this.showVideo = true;
        }
      }
    } else {
      this.endSession();
    }
  }

  public async checkForProgramCompletion() {
    const studentProgram = this.programList.find((p) => p.name === programName);
    const asssociatedToken = this.tokens.find((t) => includes(t.programs, get(studentProgram, 'id', '')));

    if (this.isProgramCompleted() && asssociatedToken.allowProgressRerun) {
      const shouldRestartProgress: boolean = await this.shouldRestartProgress();

      if (shouldRestartProgress) {
        this.openProgressRestartDialog(asssociatedToken);
      }
    }
  }

  public openProgressRestartDialog(token: Token) {
    const studentLanguage = this.themeInfo.languages.find((l) => l.languageCode === this.student.language);
    const studentProgram = this.programList.find((p) => p.name === programName);
    const levels = studentLanguage.enabledLevels.filter((l) => l.enabled);

    if (!studentProgram) {
      this._snackBar.open('Could not find the student program!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    }

    const dialog = this.dialog.open(ProgramCompletionDialog, {
      width: '505px',
      height: '300px',
      panelClass: 'client-list-modalBox',
      disableClose: true,
      data: {
        level: StudentLevel[this.getStudentLevel()],
        allowedLevels: levels,
        assessmentProgresses: this.assessmentProgress,
        studentId: this.student.id,
        programId: studentProgram.id,
      },
    });

    dialog.afterClosed().subscribe(async (shouldIncreaseLevel) => {
      this.loadingInfo = true;

      await this._rest.put('token/addRerun/tokenId/' + token.id, { msg: 'Could not add rerun' });

      if (shouldIncreaseLevel) {
        await this.studentInfoHelper.IncreaseAssessmentLevel(
          this.allProgress,
          this.student,
          this.studentProgram.id,
          this.themeInfo,
        );
      }

      await this.ngOnInit();
    });
  }

  public async endSession() {
    if (!this.showFiveDaysMenu && this.gameComponentRef) {
      this.gameComponentRef.endSession();
    }

    this.showFiveDaysMenu = false;
    this.showGame = false;
    this.showVideo = false;
    this.loadingInfo = true;
    await this._getStudentProgress(this.studentId);
    this.loadingInfo = false;
  }

  public async getCategoryList() {
    this.categoryList = await this.globalConfigHelper.getCategories();
    this.coreCategoryList = this.categoryList.filter(
      (c) => get(c, 'containerType', ContainerTypes.COREPROGRAM) === ContainerTypes.COREPROGRAM,
    );
    this.extrasCategoryList = this.categoryList.filter((c) => c.containerType === ContainerTypes.EXTRAS);
  }

  public async getStudentPageInfo() {
    const studentId = this._activatedRoute.snapshot.paramMap.get('studentId');
    const response: StudentPageInfo = await this._rest.get('student/studentPage/' + studentId);

    if (!response.studentBelongsToUser && !this.shouldAllowDebugMode(response)) {
      this.router.navigate(['']);
    }
  }

  public shouldAllowDebugMode(pageInfo: StudentPageInfo): boolean {
    return this.isDebugMode && pageInfo.isAdminUser;
  }

  public async getLanguages() {
    this.languageList = (await this.globalConfigHelper.getLanguages()) || [];
  }

  public getStudentLanguage() {
    const studentLanguage = this.languageList.find((l) => l.languageCode === this.student.language);

    return studentLanguage ? studentLanguage.name : this.student.language;
  }

  public async getProgramList() {
    const { programs } = await this._rest.get('programs');

    this.programList = programs ? programs : [];
  }

  public async goToStudentProfile() {
    const { ...studentProfile } = this.student;

    const queryParams = await this.studentHelper.getProfileQueryParams(studentProfile, this.themeInfo.label.en_ca);

    this.router.navigate(['/students/profile/' + this.student.patronId], {
      queryParams,
    });
  }

  public async getUserRoles() {
    this.isPortalAdmin = await this._auth.isPortalAdmin();
    this.isPatron = this._roles.isPatron();
    this.isOrgAdmin = this._roles.isOrgAdmin();
    this.isOrgManager = this._roles.isOrgManager();
    this.isB2C = this._roles.isB2C();
  }

  public getCurrentSession(category: Category): number {
    const categoryProgress = this.getCategoryProgress(category);
    const startingSession = category.isBonus && !categoryProgress ? category.startingSession + 1 : 1;
    const maxSessions = category.isBonus ? category.startingSession + category.maxSessions : category.maxSessions;

    const progressSession =
      get(categoryProgress, 'session', 1) < maxSessions
        ? get(categoryProgress, 'session', 1) + 1
        : category.maxSessions;

    return categoryProgress ? progressSession : startingSession;
  }

  public shouldUnlockResults(category: Category) {
    return category.isBonus
      ? this.getCurrentSession(category) === category.startingSession + 1
      : this.getCurrentSession(category) === 1;
  }

  public getCategoryLastScore(category: Category): string | undefined {
    const categoryProgress = this.getCategoryProgress(category);

    if (!categoryProgress) {
      return undefined;
    }

    const metadata = categoryProgress.metadata;

    if (!isUndefined(metadata.score)) {
      return metadata.score.toString();
    } else if (metadata.exercises && !metadata.savedGame) {
      const totalScore = metadata.exercises
        .map((e) => e.score)
        .reduce((accumulatedScore: number, exerciseScore: number) => {
          return accumulatedScore + exerciseScore;
        }, 0);

      return totalScore.toString();
    }
  }

  public getCategoryLastAccess(category: Category): string | undefined {
    const categoryProgress = this.getCategoryProgress(category);

    if (!categoryProgress) {
      return undefined;
    }

    const lastAccess = new Date(categoryProgress.creationTime);

    return moment(lastAccess).format('LL');
  }

  public getCategoryProgress(category): Progress | undefined {
    return this.progress.find((p) => p.tag === category.name.toLowerCase() && !p.metadata.savedGame);
  }

  public getLastProgress(category: Category) {
    const categoryProgress = this.getCategoryProgress(category);

    return categoryProgress;
  }

  public getAgeFromBirthday(birthdate: string): string {
    if (!birthdate) {
      return '--';
    }
    const ageDifMs = Date.now() - new Date(birthdate).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970).toString() + ' ' + 'years old';
  }

  public isLessonEnabled(category: Category) {
    // Check if the client should have access to the games before we let the games to be played
    const clientAcess = this.shouldBlockClientAccess();
    if (clientAcess.shouldBlockAccess) {
      return false;
    }

    const themeHasAssessment: boolean = this.studentInfoHelper.themeHasAssessment(this.themeInfo);
    const assessmentCount = this.assessmentProgress.length;

    return this.programHelper.shouldUnlockLesson(
      this.waitByPass,
      category,
      this.progress,
      themeHasAssessment,
      assessmentCount,
    );
  }

  public shouldBlockClientAccess(): ClientAccess {
    const studentProgram = this.programList.find((p) => p.name === programName);
    const asssociatedToken = this.tokens.find((t) => includes(t.programs, get(studentProgram, 'id', '')));

    if (this.isPatron && !this.isB2C && !this.allowCompleteAtHome) {
      return {
        shouldBlockAccess: true,
        blockType: LockTypes.completeAtHome,
      };
    } else if (asssociatedToken.allowProgressRerun && asssociatedToken.enabled === false) {
      return {
        shouldBlockAccess: true,
        blockType: LockTypes.failedPayment,
      };
    }

    return {
      shouldBlockAccess: false,
      blockType: undefined,
    };
  }

  public getStudentLevel() {
    const level = this.studentInfoHelper.getStudentLevel(this.student, this.themeInfo, this.allProgress) || 0;
    const studentLevel: string = this.studentInfoHelper.returnLevelMap(level);
    return studentLevel.toUpperCase();
  }

  public getNextSessionMessage(category: Category) {
    const progress = this.getCategoryProgress(category);

    if (!this.programHelper.canPlayNextSession(progress, category) && !this.waitByPass) {
      const time = moment(new Date(progress.creationTime)).add(category.sessionInterval, 'hours').calendar();
      return `Next session will be avaliable ${time.toLowerCase()}`;
    }

    return '';
  }

  public getLockedSessionMessage(unlockConditions: UnlockCondition[]) {
    let message = '';

    for (const condition of unlockConditions) {
      if (condition.session > 0) {
        message += `Complete ${condition.name} session ${condition.session} to unlock this category \n`;
      } else {
        message += `You need to play the ${condition.name} game to unlock this category`;
      }
    }

    return message;
  }

  public isWeeklyProgress(category: Category): boolean {
    return category.graphType === GraphTypes.doughnut || category.graphType === GraphTypes.pie;
  }

  public getSessionDay(category: Category): number {
    const sessionIndex = this.getCurrentSession(category) - 1;
    const dayIndex = sessionIndex % 5;
    const sessionDay = dayIndex + 1;
    return sessionDay;
  }

  public forceStartGame() {
    this.showFiveDaysMenu = false;
    this.gameLoading = true;
    this.startGame(this.selectedLesson, true);
  }

  public async startVideoCategory(category: Category) {
    const dialog = this.dialog.open(VideoCategoryComponent, {
      data: {
        category,
        student: this.student,
      },
      width: '600px',
      height: '500px',
      disableClose: true,
      panelClass: 'modal-border',
    });

    dialog.afterClosed().subscribe((videoContent) => {
      if (videoContent) {
        this.videoSection = videoContent.videoSection;
        this.videoContent = videoContent;
        this.showVideo = true;
      }
    });
  }

  public startGameCategory(category: Category, forceStart = false) {
    this.showGame = true;
    const level = this.studentInfoHelper.getStudentLevel(this.student, this.themeInfo, this.allProgress, category) || 0;
    const session = !this.isWeeklyProgress(category) ? this.getCurrentSession(category) : this.getSessionDay(category);
    const week = Math.ceil(this.getCurrentSession(category) / 5);
    const theme = this.themeInfo.variableName || classic;
    this.selectedLesson = category;

    if (this.isWeeklyProgress(category) && !forceStart) {
      this.buildFiveDaysMenu(category);
      this.fiveDaysMenu = fiveDaysMenu(session, this.student.language, this.getSessionDay(category), theme);
      this.showFiveDaysMenu = true;
      this.gameLoading = false;
      return;
    }

    this.gameStudentDTO = {
      name: this.studentName,
      language: this.student.language,
      level,
      program: 1,
      progress: session,
    };

    this.queryData = {
      week: `l${week}`,
      level,
      category: category.id,
      categoryTitle: category.title,
      session,
      lang: this.student.language,
      theme,
      isBonus: get(category, 'isBonus', false),
    };

    this.checkIfStudentHaveSavedGames(category);
  }

  public async startGame(category: Category, forceStart = false) {
    switch (category.type) {
      case CategoryType.VIDEO:
        this.startVideoCategory(category);
        break;
      default:
        this.startGameCategory(category, forceStart);
        break;
    }
  }

  public checkIfStudentHaveSavedGames(category: Category) {
    this.gameLoading = true;
    const savedGame = this.savedGameProgress.find(
      (s) =>
        s.tag.toLowerCase() === category.name.toLowerCase() &&
        (s.metadata?.theme === this.student?.theme || this.themeInfo?.variableName === s.metadata?.theme),
    );

    if (savedGame) {
      const savedGameDialog = this.dialog.open(savedGameDialogComponent, {
        width: '600px',
        panelClass: 'custom-modalbox',
        data: {
          savedGame: savedGame,
          student: this.studentName,
        },
        disableClose: true,
      });

      savedGameDialog.afterClosed().subscribe((state) => {
        switch (state) {
          case GameState.PLAY_FROM_BEGGINING:
            this.savedGameProgress = this.savedGameProgress.filter(
              (p) => p.tag.toLowerCase() !== category.name.toLowerCase(),
            );
            this.gameLoading = false;
            break;

          case GameState.LOAD_GAME:
            this._snackBar.open('Your game has been loaded!', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            this.gameLoading = false;
            break;

          default:
            this.endSession();
            break;
        }
      });
    } else {
      this.gameLoading = false;
    }
  }

  public async saveProgress(unityMessage) {
    const savedProgress: any = JSON.parse(unityMessage.values);
    const index = savedProgress.exerciseIndex;
    const exerciseList = savedProgress.exercises;

    if (exerciseList.length === 0 || this.isDebugMode) {
      return;
    }

    await this._rest.post(
      'progress/saveGame',
      {
        progress: {
          session: this.getCurrentSession(this.selectedLesson),
          metadata: {
            index,
            exerciseList,
            savedGame: true,
            theme: this.student.theme,
          },
          studentId: this.studentId,
          programId: this.programInfo.id,
          tag: this.selectedLesson.name.toLowerCase(),
        },
      },
      { msg: 'Could not post progress.' },
    );
  }

  private studentLevelEquation(score: number) {
    const ageDifMs = Date.now() - new Date(this.student.birthdate).getTime();
    const ageDate = new Date(ageDifMs);
    const studentAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    const themeVariableName = this.themeInfo.variableName;

    const equation =
      this.studentInfoHelper.returnStudentLevel[themeVariableName] ||
      this.studentInfoHelper.returnStudentLevel[classicThemeLabel];
    const level = equation(score, studentAge);

    return level;
  }

  public async deleteSavedGame(tag: string, studentId: string) {
    await this._rest.delete('progress/saveGame/' + tag + '/' + studentId, {
      msg: 'Could not delete the saved game',
    });
  }

  public isProgressValid(progress: Progress, message: string): boolean {
    if (includes(message, 'assessment')) {
      const score = get(progress, 'score', undefined);

      return score != undefined;
    } else {
      const exercises = get(progress, 'exercises', []);

      return exercises.length > 0;
    }
  }

  public async shouldRestartProgress(): Promise<boolean> {
    const studentToken = this.tokens.find((t) => includes(t.programs, get(this.studentProgram, 'id', '')));

    return studentToken.allowProgressRerun && studentToken.enabled === true;
  }

  public isProgramCompleted(): boolean {
    const categories = this.programInfo.categories;
    const booleanArray = [];

    for (const category of categories) {
      if (!category.mandatory) {
        booleanArray.push(true);
        continue;
      }

      const progress = this.progress.find((p) => p.tag.toLowerCase() === category.name.toLowerCase());
      const isCompleted = this.checkProgressCompletion(progress, category.maxSessions);

      booleanArray.push(isCompleted);
    }

    return booleanArray.every((e) => e === true);
  }

  public checkProgressCompletion(progress: Progress, maxLessons: number): boolean {
    return progress ? progress.session >= maxLessons : false;
  }

  public async completeUnitySession(unityMessageValues) {
    const studentProgram = this.programList.find((p) => p.name === programName);
    const asssociatedToken = this.tokens.find((t) => includes(t.programs, get(studentProgram, 'id', '')));
    const validExercises = get(unityMessageValues, 'exercises', []).filter((e) => e.name !== VIDEO);

    const exerciseName = get(validExercises[0], 'name', '');
    const level: number = this.studentLevelEquation(get(validExercises[0], 'score'));
    const studentLevel = this.studentInfoHelper.getStudentLevel(
      this.student,
      this.themeInfo,
      this.allProgress,
      this.selectedLesson,
    );
    const studentLevelCode = this.studentInfoHelper.returnLevelMap(studentLevel);

    const progressData: ProgressData = {
      level,
      studentId: this.studentId,
      programId: this.programInfo.id,
      session: this.getCurrentSession(this.selectedLesson),
      rerun: asssociatedToken.progressRun || 0,
      tag: this.selectedLesson.name.toLowerCase(),
      levelCode: studentLevelCode,
    };

    const body = this.programHelper.createProgressBody(exerciseName, this.queryData, progressData, unityMessageValues);
    this._logger.info(UnityMessages.SESSION_COMPLETE, 'exerciseName', exerciseName);
    const progressMetadata = get(body, 'progress.metadata');
    const shouldOpenBeforeYouStart = this.shouldOpenBeforeYouStart(this.selectedLesson.name.toLowerCase());

    if (this.isProgressValid(progressMetadata, exerciseName)) {
      await this._rest.post('progress', body, { msg: 'Could not post progress' });

      // Delete the saved game after we save the user progress
      await this.deleteSavedGame(this.selectedLesson.name.toLowerCase(), this.studentId);
    }

    await this._getStudentProgress(this.studentId);

    const shouldRestartProgress: boolean = asssociatedToken.allowProgressRerun
      ? await this.shouldRestartProgress()
      : false;

    if (this.shouldSendCertificate()) {
      await this._rest.post('/patron/' + this.student.patronId + '/student/' + this.student.id + '/certificate', {
        studentLevel: this.getStudentLevel(),
      });
    }

    if (this.isProgramCompleted() && shouldRestartProgress) {
      this.openProgressRestartDialog(asssociatedToken);
    } else if (this.selectedLesson && !shouldOpenBeforeYouStart) {
      this.openResultsDialog(this.selectedLesson);
    } else if (shouldOpenBeforeYouStart) {
      this.dialog.open(BeforeYouStartComponent);
    }
  }

  public async sessionComplete(unityMessage: UnityMessages) {
    if (this.isDebugMode) {
      this.showGame = false;
      return;
    }

    const message = get(unityMessage, 'message', '');

    if (message === UnityMessages.SESSION_COMPLETE) {
      const unityMessageValues = JSON.parse(get(unityMessage, 'values'));
      await this.completeUnitySession(unityMessageValues);
    }

    this.showGame = false;
  }

  public shouldSendCertificate(): boolean {
    return this.isProgramCompleted() && !this.student.certificateIssued && get(this.selectedLesson, 'mandatory', false);
  }

  public shouldOpenBeforeYouStart(categoryName: string): boolean {
    return this.allProgress.length === 0 && categoryName.toLowerCase() === progressTypes.ASSESSMENT;
  }

  public async getThemeInformation(studentTheme: string) {
    const theme = await this._rest.get('themes/id/' + studentTheme, {
      msg: 'Could not find this theme',
    });

    if (theme) {
      if (!theme.categories) {
        theme.categories = defaultCategoryTypes;
      }

      this.themeInfo = theme;
    }
  }

  private async _getProgramInfo(): Promise<void> {
    this.programInfo = await this.programHelper.getProgramInfo(this.themeInfo);
  }

  private async _getStudentInfo(studentId: string) {
    this.user = await this._auth.getUser();
    const response = await this._rest.get('student/' + studentId, { msg: 'Could not find the student' });
    this.student = response.student;
    this.student.tokens = response.tokens;
    this.tokens = response.tokens;
    this.studentName = this.student.nickname || this.student.fullname.split(' ')[0];

    const studentProgram = this.programList.find((p) => p.name === programName);
    const asssociatedToken = this.tokens.find((t) => includes(t.programs, get(studentProgram, 'id', '')));
    this.allowCompleteAtHome = asssociatedToken.allowCompleteAtHome;
    this.selectedRerun = asssociatedToken.progressRun || 0;
    this.tokenHigherRun = asssociatedToken.progressRun || 0;

    this.studentTheme = this.student.theme;
    const language = this.student.language.length > 2 ? this.student.language.substring(0, 2) : this.student.language;
    const findTranslation = this.translate.langs.find((t) => t === language);

    if (findTranslation) {
      this.translate.setDefaultLang(language);
    } else {
      this.translate.setDefaultLang('en');
    }
  }

  public async changeSelectedRun() {
    this.loadingInfo = true;
    this.selectedRerun = Number(this.selectedRerun);
    await this._getStudentProgress(this.studentId);
    this.loadingInfo = false;
  }

  private async _getStudentProgress(studentId: string): Promise<void> {
    const response = await this._rest.get('progress/student/' + studentId, {
      msg: 'Could not get progress/student/:studentId.',
    });

    this.allProgress = get(response, 'progress', []);
    this.assessmentProgress = this.allProgress.filter((p) => p.tag === progressTypes.ASSESSMENT);
    this.savedGameProgress = this.allProgress.filter((p) => p.metadata?.savedGame);

    this.allProgress = this.allProgress.filter((p) => !p.metadata.savedGame);

    const latestProgress = await this._rest.get('progress/student/' + studentId, {
      params: new HttpParams().set('options', 'latest').set('rerun', this.selectedRerun),
      msg: 'Could not get progress/student/:studentId',
    });

    const latestProg = get(latestProgress, 'progress', []);

    this.progress = latestProg.filter((p: Progress) => this.shouldIncludeProgress(p));

    const rerunOptions = latestProg.map((p: Progress) => p.rerun ?? 0);
    rerunOptions.push(this.tokenHigherRun);
    const uniqueRerunOptions: number[] = uniq(rerunOptions, 'rerun').sort();
    this.rerunOptions = uniqueRerunOptions.reverse();
  }

  public shouldIncludeProgress(progress: Progress) {
    const rerun = progress.rerun || 0;

    if (this.isMandatoryCategory(progress.tag)) {
      return rerun === this.selectedRerun;
    } else {
      return true;
    }
  }

  public isMandatoryCategory(tag: string): boolean {
    const category = this.categoryList.find((c) => c.name.toLowerCase() === tag);

    if (!category) {
      return false;
    }

    return category.mandatory;
  }

  public openResultsDialog(category: Category) {
    const categoryProgress = this.allProgress.filter(
      (p) => p.tag === category.name.toLowerCase() && !p.metadata.savedGame,
    );

    this.dialog.open(StudentResultsDialog, {
      width: '930px',
      height: '860px',
      panelClass: 'graph-modalBox',
      data: {
        progress: categoryProgress,
        category,
        student: this.student,
        themeInfo: this.themeInfo,
        completeProgress: this.allProgress,
        selectedRerun: this.selectedRerun,
      },
    });
  }

  public byPass() {
    if (this.byPassCounter < 10) {
      this.byPassCounter += 1;
    } else if (!this.waitByPass) {
      this._logger.info('Congratulations, you just unlocked the wait time bypass!');
      this.waitByPass = true;
    }
  }

  public getCategoryName(title: string) {
    const categories = this.programInfo.categories;
    const selectedCategory = categories.find((c) => c.title === title);

    return selectedCategory ? selectedCategory.name : '';
  }

  public async allowRemoteAccess(allowRemoteAccess: boolean) {
    const tokens: Token[] = this.tokens;

    for (const token of tokens) {
      token.allowCompleteAtHome = allowRemoteAccess;
      await this._rest.put('token/' + token.id, { token }, { msg: 'Could not put token.' });
    }

    this._snackBar.open(
      `Remote access ${allowRemoteAccess ? 'granted' : 'removed'} for the student ${this.studentName}`,
      'Close',
      {
        verticalPosition: 'top',
        horizontalPosition: 'center',
      },
    );
  }

  public getConditionToUnlockMessage(category: Category) {
    let lockedSessionMessage = '';

    const clientAcess = this.shouldBlockClientAccess();

    if (clientAcess.shouldBlockAccess) {
      switch (clientAcess.blockType) {
        case LockTypes.completeAtHome:
          return clientBlockedAccessMessage;
        case LockTypes.failedPayment:
          return clientFailedPaymentBlockedAccessMessage;
        default:
          break;
      }
    }

    const assessmentCount = this.assessmentProgress.length;
    const failedConditions = this.programHelper.getFailedUnlockConditions(this.progress, category, assessmentCount);

    for (const condition of failedConditions) {
      const message = this.conditionToUnlockMessages[condition.type];
      lockedSessionMessage += message(category);
    }

    return lockedSessionMessage;
  }

  public fillStudentAgenda() {
    const agenda = this.student.agenda;

    if (!agenda) {
      return;
    }

    for (const keys of Object.keys(agenda)) {
      const label = {
        title: this.getCategoryName(keys),
        color: colors[keys],
      };

      this.calendarLabels.push(label);

      agenda[keys] = agenda[keys].map((d) => {
        return {
          title: this.getCategoryName(keys),
          start: new Date(d.start),
          color: colors[keys],
        };
      });
    }

    this.events = [...agenda.cognitiveTherapy, ...agenda.readingExercises, ...agenda.speedReading];
  }

  public getLabelColor(label) {
    const color = label.primary;

    return `color: ${color}`;
  }

  public openCategoryInfo(category: Category) {
    this.dialog.open(CategoryInfoDialog, {
      data: category,
      panelClass: 'modal-border',
      width: '630px',
    });
  }

  public async openOverrideDialog() {
    const studentLanguage = this.themeInfo.languages.find((l) => l.languageCode === this.student.language);

    if (!studentLanguage) {
      this._snackBar.open('Couldnt find any enabled levels for this language. Check the student language', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }

    const levels = studentLanguage.enabledLevels.filter((l) => l.enabled);
    const assessmentProgresses = this.allProgress.filter((p) => p.tag === progressTypes.ASSESSMENT);
    const studentProgram = this.programList.find((p) => p.name === programName);
    const asssociatedToken = this.tokens.find((t) => includes(t.programs, get(studentProgram, 'id', '')));

    if (!studentProgram) {
      this._snackBar.open('Couldnt find the student program. Please try again', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    } else if (!asssociatedToken) {
      this._snackBar.open('Couldnt find the student associated token. Please try again', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      return;
    }

    const dialog = this.dialog.open(LevelOverrideDialogComponent, {
      data: {
        levels,
        assessmentProgresses,
        studentLevel: this.getStudentLevel(),
        student: this.student,
        program: studentProgram,
        categories: this.programInfo.categories.filter((c) => c.name !== upperFirst(progressTypes.ASSESSMENT)),
        bonusPackList: this.programInfo.bonusPacks,
        asssociatedToken,
      },
      width: '720px',
      height: '590px',
      panelClass: 'modal-border',
    });

    dialog.afterClosed().subscribe(async (shouldReload: boolean) => {
      if (shouldReload) {
        this.loadingInfo = true;
        await this.ngOnInit();
      }
    });
  }

  public getEnabledBonusPacks() {
    const enabledBonusPacks = [];
    const bonusPackList = this.programInfo.bonusPacks;

    for (const bonus of bonusPackList) {
      const studentProgram = this.programList.find((p) => p.name === programName);
      const asssociatedToken = this.tokens.find((t) => includes(t.programs, get(studentProgram, 'id', '')));

      const isAvaliable: boolean = includes(asssociatedToken?.enabledBonus, bonus.id);

      if (isAvaliable) {
        enabledBonusPacks.push(bonus);
      }
    }

    return enabledBonusPacks;
  }

  public getAvaliableCategories(): Category[] {
    const regularCategories = this.programInfo.categories;
    const bonusCategories = this.getEnabledBonusPacks();
    return [].concat(regularCategories, bonusCategories);
  }

  public getCoreProgram() {
    const categories = this.getAvaliableCategories();
    this.coreCategoryList = categories.filter(
      (c: Category) => get(c, 'containerType', ContainerTypes.COREPROGRAM) === ContainerTypes.COREPROGRAM,
    );

    return this.coreCategoryList || [];
  }

  public getExtras() {
    const categories = this.getAvaliableCategories();
    this.extrasCategoryList = categories.filter((c: Category) => c.containerType === ContainerTypes.EXTRAS);

    return this.extrasCategoryList || [];
  }

  public buildFiveDaysMenu(category: Category) {
    const sessionDay = this.getSessionDay(category);
    const stateArray = [];

    for (let i = 0; i < 5; i++) {
      if (i + 1 > sessionDay) {
        stateArray.push(ImageDaysStates.LOCKED);
      } else if (i + 1 < sessionDay) {
        stateArray.push(ImageDaysStates.CHECKED);
      } else {
        stateArray.push(ImageDaysStates.DEFAULT);
      }
    }

    this.dayStateArray = stateArray;
  }

  public handleUnityClose() {
    this.allowUnityClose = true;
  }
}
