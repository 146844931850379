import { Component, OnInit, ApplicationRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

import { Discount, PricingBundle } from '../pricing/interfaces/pricing-bundle.interface';
//  defaultResellerBundles,
import { defaultB2CBundles } from '../pricing/default-readls-bundles/default-readls-bundles.constants';

import { NotificationClass } from 'src/app/shared/classes/notification.class';
import { User, Organization } from 'src/app/shared/models/user.model';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faCreditCard, faAngleDown, faAngleUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons';

import { get, isEmpty, sortBy } from 'lodash';

import { SelectBundleService } from 'src/app/pages/programs-pricing/services/select-bundle.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { GlobalConfigurationHelper } from 'src/app/services/utils/global-configuration-helper';
import { UsersHelperService } from '../users/users-helper.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import {
  StripeCountryWithState,
  StripeDecimalMultiplier,
  StripeTax,
} from 'src/app/shared/interfaces/Stripe.interfaces';
import { getCountries } from 'src/app/shared/helpers/getCountries.helper';
import { getCanadianStates } from 'src/app/shared/helpers/getCanadianStates.helper';
import { getUsStates } from 'src/app/shared/helpers/getUsStates';

declare let stripe: any;
declare let elements: any;

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent extends NotificationClass implements OnInit, OnDestroy {
  public outsiderBundles: PricingBundle[] = [
    {
      id: '2',
      title: 'Single.',
      price: 1275,
      studentsIncluded: 1,
      hiddenPrice: false,
      currency: 'USD',
    },
    {
      id: '3',
      title: 'Trio.',
      price: 3300,
      studentsIncluded: 3,
      hiddenPrice: false,
      currency: 'USD',
    },
    {
      id: '4',
      title: 'Pro.',
      price: 0,
      hiddenPrice: true,
      message:
        '<span>Please email us at <a href="mailto:aspiremg.ls@gmail.com">aspiremg.ls@gmail.com</a> for special pricing and discounts for educators and mentors.</span>',
      currency: 'USD',
    },
  ];

  public bundles: PricingBundle[] = [];
  public selectedBundle: PricingBundle;
  public quantity = 1;
  public couponCode: string;
  public couponCodeResponse: Discount;
  public name;
  public email;
  public referral;
  public isFormSubmited = false;
  private user: User;
  private organization: Organization;
  public tax: StripeTax;

  public validInput = {
    cardNumber: { empty: true, complete: false },
    cardExpiry: { empty: true, complete: false },
    cardCvc: { empty: true, complete: false },
    sending: false,
  };
  public saveCard: boolean;
  public checkIcon: IconDefinition = faCheck;
  public loading: IconDefinition = faSpinner;
  public ccVisa: IconDefinition = faCcVisa;
  public ccMaster: IconDefinition = faCcMastercard;
  public ccDefault: IconDefinition = faCreditCard;
  public arrowDown: IconDefinition = faAngleDown;
  public arrowUp: IconDefinition = faAngleUp;
  public visa: IconDefinition = faCcVisa;
  public master: IconDefinition = faCcMastercard;
  public amex: IconDefinition = faCcAmex;
  public openSavedCards = false;
  public savedCards: any[];
  public selectedCard: string;
  public cardNumberElement;
  public cardExpiryElement;
  public cardCvcElement;
  public subscriptionAgreement = false;

  public isPatron = false;

  constructor(
    protected _snackBar: MatSnackBar,
    public _rest: RestAPIService,
    public _activatedRoute: ActivatedRoute,
    public appRef: ApplicationRef,
    public _router: Router,
    public _auth: AuthService,
    public bundleService: SelectBundleService,
    private _logger: LoggerService,
    private _roles: RoleService,
    private globalConfiguration: GlobalConfigurationHelper,
    private userHelper: UsersHelperService,
    private _analyticsService: AnalyticsService,
  ) {
    super(_snackBar);
  }

  async ngOnInit() {
    const bundleId = this._activatedRoute.snapshot.paramMap.get('bundleId');
    this.selectedBundle = this.bundleService.selectedBundle ?? (await this.getBundle(bundleId));
    this.isPatron = this._roles.isPatron();
    this.user = this._roles.user;
    this.organization =
      this.user.organization ||
      get(await this._rest.get('organization/self', { msg: 'Could not get organization.' }), 'organization');

    if (this.bundleService.bundleList.length === 0) {
      this.bundleService.bundleList = await this.globalConfiguration.getProgramBundles();
    }

    this._loadBundles(bundleId);
    this.stripeSubmitCard();
    await this.loadTax();

    if (!this.selectedBundle) {
      this._router.navigate(['programs-pricing']);
    }
  }

  public isInstallmentPaymentBundle(bundle: PricingBundle): boolean {
    const interval = get(bundle, 'recurring.interval', bundle.interval);
    const period = get(bundle, 'subscriptionPeriod', '');
    return interval && period;
  }

  public isSubscriptionBundle(bundle: PricingBundle): boolean {
    const interval = get(bundle, 'recurring.interval', bundle.interval);
    const period = get(bundle, 'subscriptionPeriod', '');
    return interval && !period;
  }

  public getBundleInterval(bundle: PricingBundle): string {
    if (!bundle) {
      return '';
    }

    const interval = get(bundle, 'recurring.interval', bundle.interval);

    if (!interval) {
      return '';
    }

    return interval;
  }

  private async loadTax() {
    const country = get(this.user?.organization?.address, 'country', '').toLowerCase() ?? '';
    const state = get(this.user?.organization?.address, 'province', '').toLowerCase() ?? '';

    const countryInfo = getCountries.find((c) => c.name.toLowerCase() === country);
    const stateInfo = this.getStateInfo(country, state);

    let tax: StripeTax;
    if (countryInfo?.acronym) {
      tax = await this._rest.get(`/stripe/tax/country/${countryInfo.acronym}`);
    }
    if (stateInfo?.acronym) {
      tax = await this._rest.get(`/stripe/tax/state/${stateInfo.acronym}`);
    }

    if (tax) {
      this.tax = tax;
    }
  }

  private getStateInfo(country: string, state: string): { acronym: string } | undefined {
    if (country === StripeCountryWithState.CA) {
      return getCanadianStates.find((s) => s.acronym.toLowerCase() === state || s.name.toLowerCase() === state);
    }
    if (country === StripeCountryWithState.US) {
      return getUsStates.find((s) => s.acronym.toLowerCase() === state || s.name.toLowerCase() === state);
    }
  }

  public async getBundle(bundleId: string) {
    const bundle = await this._rest.get('stripe/price/active');
    return bundle.find((b) => b.id === bundleId);
  }

  public async checkCoupon(): Promise<void> {
    if (!this.couponCode) {
      return;
    }

    try {
      const response = await this._rest.get('coupon/verify/' + this.couponCode);
      if (response.coupon) {
        this.couponCodeResponse = {
          isValid: true,
          discountAmount: response.coupon.discountAmount,
          discountPercentage: response.coupon.discountPercentage,
          id: response.coupon.id,
        };
      } else {
        this.couponCodeResponse = null;
        this.couponCode = '';
      }
    } catch (err) {
      if (err.error && err.error.frontError) {
        this.notify(err.error.frontError);
      }
      this.couponCodeResponse = null;
      this.couponCode = '';
    }
  }

  public getDiscountAmount(): number {
    if (!this.couponCodeResponse) {
      return 0;
    }
    // If the dicount is negative return 0
    if (
      get(this.couponCodeResponse, 'discountAmount', 0) < 0 ||
      get(this.couponCodeResponse, 'discountPercentage', 0) < 0
    ) {
      return 0;
    }

    let discountAmount = 0;

    if (this.couponCodeResponse.discountAmount) {
      discountAmount = this.couponCodeResponse.discountAmount;
    } else {
      discountAmount = (this.couponCodeResponse.discountPercentage / 100) * this.selectedBundle.price * this.quantity;
    }

    if (discountAmount > this.selectedBundle.price * this.quantity) {
      discountAmount = this.selectedBundle.price * this.quantity;
    }

    return discountAmount;
  }

  public getOrderTotal(): number {
    if (!this.selectedBundle) {
      return 0;
    }

    const price = this.selectedBundle.unit_amount ? this.selectedBundle.unit_amount : this.selectedBundle.price;
    const subtotal = this.selectedBundle.price
      ? price * this.quantity - this.getDiscountAmount()
      : (price * this.quantity - this.getDiscountAmount()) / StripeDecimalMultiplier.Hundred;

    if (!this.tax?.percentage) {
      return Math.max(subtotal, 0);
    }

    const taxPercentage = this.tax.percentage / StripeDecimalMultiplier.Hundred;
    let total: number;

    if (this.tax?.inclusive === true) {
      total = subtotal;
    } else {
      const taxAmount = subtotal * taxPercentage;
      total = subtotal + taxAmount;
    }

    return Math.max(total, 0);
  }

  public getCardIcon(icon) {
    switch (icon.toLowerCase()) {
      case 'visa':
        return this.ccVisa;
      case 'mastercard':
        return this.ccMaster;
      default:
        return this.ccDefault;
    }
  }

  private async getResellerBundles(orgId?) {
    let resellerId;

    if (orgId) {
      resellerId = orgId;
    } else {
      resellerId = get(this.user, 'belongsTo.id', get(this._auth.getOrgAcc(), 'belongsTo.id'));
    }

    const bundlesResponse = await this._rest.get(`bundle/${resellerId}`, {
      msg: 'Could not get bundle/:resellerId.',
    });
    const { bundles: customBundles } = bundlesResponse;

    let defaultBundles = customBundles.filter((b) => isEmpty(b.belongsTo));
    const outsiderBundles = customBundles.filter((b) => b.belongsTo === this.user.id);

    if (defaultBundles.length < 3) {
      defaultBundles.forEach((e) => {
        this.outsiderBundles.splice(e.order, 1, e);
      });
      defaultBundles = this.outsiderBundles;
    }

    defaultBundles = sortBy(defaultBundles, 'order');

    if (outsiderBundles.length > 0) {
      outsiderBundles.forEach((e) => {
        defaultBundles.splice(e.order, 1, e);
      });
    }

    defaultBundles = defaultBundles.filter((b: PricingBundle) =>
      this._auth.user.firstTokenPurchased ? !b.isFirstPurchaseBundle : b.isFirstPurchaseBundle,
    );

    return defaultBundles.filter((b) => b.price > 0);
  }

  public async _loadBundles(bundleId: string) {
    try {
      await this._auth.getUser();

      if (this.organization.isOutsider && this._roles.access.level !== 'B2C') {
        this.bundles = await this.getResellerBundles();
        this.bundleService.bundleList = this.bundles;
      } else {
        if (this._roles.access.level === 'B2C') {
          defaultB2CBundles.price = this.organization.b2cTokenPrice;
          defaultB2CBundles.currency = this.organization.b2cCurrency;
          this.bundles = [defaultB2CBundles];
        } else {
          this.bundles = await this.getRegularBundles();
        }
      }
      this.bundles = [this.bundleService.bundleList.find((b) => b.id === bundleId)];

      if (!this.selectedBundle.subscription) {
        this.subscriptionAgreement = true;
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  public async getRegularBundles() {
    const prices = await this._rest.get('stripe/price/active');
    return prices.data;
  }

  public async getUserCards() {
    await this._rest.get('user/cards/self', { msg: 'Could not get user cards.' }).then(
      (res) => {
        if (res.cards.length > 0) {
          this.savedCards = res.cards;
        }
      },
      (err) => this._logger.error(err),
    );
  }

  public stripeSubmitCard() {
    const style = {
      base: {
        iconColor: '#666EE8',
        color: 'rgb(33, 37, 41)',
        lineHeight: '40px',
        fontWeight: 400,
        fontFamily: 'Roboto, Helvetica Neue, sans-serif',
        fontSize: '16px',

        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    };

    this.cardNumberElement = elements.create('cardNumber', {
      style,
    });
    this.cardNumberElement.mount('#card-number-element');

    this.cardExpiryElement = elements.create('cardExpiry', {
      style,
    });
    this.cardExpiryElement.mount('#card-expiry-element');

    this.cardCvcElement = elements.create('cardCvc', {
      style,
    });

    this.cardCvcElement.mount('#card-cvc-element');

    const setOutcome = async (result) => {
      if (result.token) {
        this.sendPayment(result.token.id, false); // TODO: substitute false for saveCardElement.checked
      } else if (result.elementType) {
        this.validInput[result.elementType] = {
          complete: result.complete,
          empty: result.empty,
        };
      }
      this.appRef.tick();
    };

    this.cardNumberElement.on('change', (event) => {
      setOutcome(event);
    });

    this.cardExpiryElement.on('change', (event) => {
      setOutcome(event);
    });

    this.cardCvcElement.on('change', (event) => {
      setOutcome(event);
    });

    document.querySelector('form').addEventListener('submit', (e) => {
      this.isFormSubmited = true;
      e.preventDefault();
      if (this.openSavedCards) {
        this.sendPayment(this.selectedCard, false);
      } else {
        stripe.createToken(this.cardNumberElement).then(setOutcome);
      }
    });
  }

  public async sendPayment(tokenId, saveCard) {
    if (!this.name || !this.email) {
      this.notify('You must fill Name and E-mail fields.', 'OK', 5000);
      this.isFormSubmited = false;
      return;
    }

    if (this.selectedBundle?.recurring && this._roles.access.level !== 'B2C') {
      this.assignSubscription(tokenId);
      return;
    } else if (this.selectedBundle.subscription && this._roles.access.level === 'B2C') {
      this.assignB2cRecurringPayment(tokenId, this.selectedBundle.paymentType);
      return;
    }

    this.validInput.sending = true;
    const interval = setInterval(() => this.appRef.tick(), 1000 / 30);
    let discount = 0;
    if (this.couponCodeResponse) {
      discount = this.couponCodeResponse.discountAmount
        ? this.couponCodeResponse.discountAmount
        : (this.couponCodeResponse.discountPercentage / StripeDecimalMultiplier.Hundred) *
          (this.selectedBundle.unit_amount / StripeDecimalMultiplier.Hundred) *
          this.quantity;
    }
    let program = await this._rest.get('programs', { msg: 'Could not get programs.' });
    program = program.programs.find((p) => p.name === 'Neuralign');

    if (this.organization?.isOutsider || this._roles.access.level === 'B2C') {
      const provider = this._roles.access.level === 'B2C' ? get(this.user, 'patron.orgId') : null;
      const belongsToOrg =
        this._roles.access.level === 'B2C'
          ? await this._rest.get('organization/findById/' + provider, {
              msg: 'Could not get organization/findById.',
            })
          : null;

      const accountId =
        this.organization?.isOutsider && this._roles.access.level !== 'B2C'
          ? get(this.user, 'belongsTo.accountId', get(this._auth.getOrgAcc(), 'belongsTo.accountId'))
          : get(belongsToOrg, 'organization.accountId');

      const outsiderAccId =
        this.organization?.isOutsider && this._roles.access.level !== 'B2C'
          ? get(this._auth.getOrgAcc(), 'id', get(this.user, 'id'))
          : get(this.user, 'id');

      try {
        const response = await this._rest.put('token/transfer', {
          outsiderAccId: { accountId: outsiderAccId },
          amount: this.selectedBundle.studentsIncluded * this.quantity,
          accountId,
          paymentSource: tokenId,
          priceAmount: this.selectedBundle.price,
          currency: this.selectedBundle.currency || 'CAD',
          programs: [program.id],
          saveCard,
          quantity: this.quantity,
          bundleQuantity: this.selectedBundle.studentsIncluded,
          discount,
          couponId: this.couponCodeResponse ? this.couponCodeResponse.id : '',
          name: this.name,
          email: this.email,
          referral: this.referral || '',
        });

        if (!response) {
          throw new Error();
        }

        this._redirectUser();
      } catch (e) {
        this.cancelPurchase(interval, e);
      }
      return;
    }

    await this._rest
      .post('token/self', {
        paymentSource: tokenId,
        priceAmount: this.selectedBundle.unit_amount / StripeDecimalMultiplier.Hundred,
        programs: [program.id],
        saveCard,
        currency: this.selectedBundle.currency || 'CAD',
        quantity: this.quantity,
        bundleQuantity: this.selectedBundle?.metadata?.tokens,
        discount,
        couponId: this.couponCodeResponse ? this.couponCodeResponse.id : '',
        name: this.name,
        email: this.email,
        referral: this.referral || '',
      })
      .then(
        (response) => {
          if (!response) {
            throw new Error('Payment failed');
          }

          this._redirectUser();
        },
        (err) => {
          this.cancelPurchase(interval, err);
        },
      );

    clearInterval(interval);
    this.validInput.sending = false;
  }

  private _redirectUser(): void {
    // Send a message to Google Analytics marking a successful purchase.
    this._analyticsService.markPurchase();

    this.notify(
      'Success! Your payment is being processed and the credit will be available in up to an hour',
      'OK',
      5000,
    );

    if (this.isPatron) {
      this.confirmB2cTokenPurchase().then(() => {
        this._router.navigate(['/students']);
      });
    } else {
      this._router.navigate(['/users']);
    }
  }

  public async confirmB2cTokenPurchase() {
    const isFirstPurchaseDone = await this.userHelper.confirmFirstPurchase();
    this._auth.user.firstTokenPurchased = isFirstPurchaseDone;
  }

  public handleTaxPercentage() {
    let orderTotal = 0;
    if (!this.selectedBundle?.tax?.percentage) {
      orderTotal =
        (this.selectedBundle?.unit_amount * this.quantity - this.getDiscountAmount()) / StripeDecimalMultiplier.Hundred;
    } else {
      if (this.selectedBundle?.tax?.inclusive === true) {
        orderTotal =
          (this.selectedBundle?.unit_amount * this.quantity - this.getDiscountAmount()) /
          StripeDecimalMultiplier.Hundred;
      } else {
        const taxPercentage = this.selectedBundle?.tax?.percentage / StripeDecimalMultiplier.Hundred;
        const tax = 1 + 1 * taxPercentage;
        orderTotal =
          (this.selectedBundle?.unit_amount * this.quantity - this.getDiscountAmount()) /
          StripeDecimalMultiplier.Hundred;
        orderTotal = orderTotal * tax;
      }
    }
    return orderTotal;
  }

  public async assignSubscription(tokenId) {
    const program = await this.getNeuralignProgram();

    this.validInput.sending = true;
    const interval = this.startAppRefInterval();
    const accountId = this.determineAccountId();
    const discount = this.calculateDiscount();

    try {
      const response = await this.sendSubscriptionRequest(accountId, tokenId, discount, program);
      if (!response) {
        throw new Error('Payment failed');
      }

      await this.checkTokensToReceive();

      this._redirectUser();
    } catch (err) {
      this.cancelPurchase(interval, err);
    }
  }

  private async getNeuralignProgram() {
    const programs = await this._rest.get('programs', { msg: 'Could not get programs.' });
    const neuralignProgram = programs.programs.find((p) => p.name === 'Neuralign');
    return neuralignProgram;
  }

  private startAppRefInterval() {
    return setInterval(() => this.appRef.tick(), 1000 / 30);
  }

  private determineAccountId() {
    const { user, _auth, organization } = this;
    return organization?.isOutsider
      ? get(user, 'belongsTo.accountId', get(_auth.getOrgAcc(), 'belongsTo.accountId'))
      : get(user, 'id');
  }

  private calculateDiscount() {
    return this.couponCodeResponse ? this.getDiscountValue(this.couponCodeResponse) : 0;
  }

  private async sendSubscriptionRequest(accountId, tokenId, discount, program) {
    // In price just send unit_amount, because the price is already calculated with the tax on stripe
    const response = await this._rest.post('subscriptions/assignSubscription', {
      accountId,
      paymentSource: tokenId,
      price: this.selectedBundle?.unit_amount / StripeDecimalMultiplier.Hundred,
      currency: this.selectedBundle.currency,
      studentsIncluded: this.selectedBundle?.metadata?.tokens,
      order: this.selectedBundle?.order,
      programs: program?.id,
      interval: this.selectedBundle?.recurring?.interval || 'month',
      discount,
      couponId: this.couponCodeResponse ? this.couponCodeResponse.id : '',
      quantity: this.quantity,
      tax_id: this.tax?.id,
    });
    return response;
  }

  private async checkTokensToReceive() {
    await this._rest.get('subscriptions/checkTokensToReceive');
  }

  public async assignB2cRecurringPayment(tokenId: string, type: string) {
    let program = await this._rest.get('programs', { msg: 'Could not get programs.' });
    program = program.programs.find((p) => p.name === 'Neuralign');

    this.validInput.sending = true;
    const interval = setInterval(() => this.appRef.tick(), 1000 / 30);

    const org = await this._rest.get('/organization/findById/' + this.user.patron.orgId, {
      msg: 'Could not finish this payment',
    });

    let discount = 0;

    const accountId = get(org, 'organization.accountId');

    if (this.couponCodeResponse) {
      discount = this.getDiscountValue(this.couponCodeResponse);
    }

    try {
      const purchaseInfo = {
        accountId,
        paymentSource: tokenId,
        price: this.selectedBundle.price,
        currency: this.selectedBundle.currency,
        studentsIncluded: this.selectedBundle.studentsIncluded,
        order: this.selectedBundle.order,
        programs: [program.id],
        interval: this.selectedBundle.interval,
        cancel_at_period_end: this.selectedBundle.cancelOnEndDate ? this.selectedBundle.cancelOnEndDate : false,
        subscriptionPeriod: this.selectedBundle.subscriptionPeriod,
        paymentType: type,
        discount,
        couponId: this.couponCodeResponse ? this.couponCodeResponse.id : '',
        quantity: this.quantity,
      };

      const response = await this._rest.post('b2c/assignRecurringPayment', purchaseInfo);

      if (!response) {
        throw new Error('Payment failed');
      }

      if (get(response, 'invoicePaid', false) === false) {
        this.notify('Success! Your credit will be avaliable when your payment is finished!', 'OK', 5000);

        this._router.navigate(['/installment-payments-control-center']);
      } else {
        this._redirectUser();
      }
    } catch (err) {
      this.cancelPurchase(interval, err);
    }
  }

  public cancelPurchase(interval, err) {
    this.validInput.sending = false;
    this.isFormSubmited = false;
    clearInterval(interval);
    this._logger.error(err);
  }

  getDiscountValue(discountResponse: Discount) {
    const discount = discountResponse.discountAmount
      ? discountResponse.discountAmount
      : (discountResponse.discountPercentage / StripeDecimalMultiplier.Hundred) *
        this.selectedBundle.price *
        this.quantity;

    return discount;
  }

  public pluralizeCredits(num: number): string {
    if (num === 1) {
      return 'credit';
    } else {
      return 'credits';
    }
  }

  ngOnDestroy() {
    this.cardNumberElement.destroy();
    this.cardExpiryElement.destroy();
    this.cardCvcElement.destroy();
  }
}
