import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ProgressManagerHelper } from 'src/app/shared/helpers/progress-manager';
import { CoursesService } from 'src/app/pages/courses/services/courses/courses.service';
import { ProgressService } from 'src/app/pages/courses/services/progress/progress.service';

@Component({
  selector: 'courses-list-card-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label: string;
  @Input() goto: 'overview' | 'course';
  @Input() courseId: string;

  private _progressManager: ProgressManagerHelper;

  constructor(
    private _router: Router,
    private _courses: CoursesService,
    private _rest: RestAPIService,
    private _auth: AuthService,
    private _progress: ProgressService,
  ) {
    this._progressManager = new ProgressManagerHelper(_rest);
  }

  public async handleClick() {
    const courseList = this._courses.getRawCourseData();
    const courseProgresses = this._courses.getRawProgressData();

    const course = courseList.find((c) => c.id === this.courseId);
    const progress = courseProgresses.find((p) => p.courseId === this.courseId);

    if (progress === undefined) {
      const user = await this._auth.getUser();

      const progress = await this._rest.post(
        'courseProgress/accountId/' + user.id,
        this._progressManager.createNewProgress(course.id, course.lessons),
      );

      this._progress.loadProgresses([progress]);
    } else {
      await this._progressManager.updateUserProgress(progress, course);
    }

    if (this.goto === 'overview') {
      this._router.navigate(['courses/overview/' + this.courseId]);
    }

    if (this.goto === 'course') {
      this._router.navigate(['courses/learning/' + this.courseId]);
    }
  }
}
