export enum Games {
  ASSESSMENT = 'Assessment',
  AUSTRALIA = 'Australia',
  CHINA = 'China',
  EGYPT = 'Egypt',
  HOLLAND = 'Holland',
  HONEYCOMB = 'Honeycomb',
  MOSAIC = 'Mosaic',
  PINBALL = 'Pinball',
  SCOTLAND = 'Scotland',
  SUNNYSCOTLAND = 'Sunnyscotland',
  COLOURHOPPER = 'Colourhopper',
  HOMEREADING = 'Homereading',
  MEMORY = 'Memory',
  PATHFINDER = 'Pathfinder',
  READINGCOMP = 'Readingcomp',
  SILLYMACHINE = 'Sillymachine',
  WORDBUILDERHOLLAND = 'Wordbuilderholland',
  SPEEDREADING = 'Speedreading',
  PHONEMEFAIR = 'PhonemeFair',
}

export enum GraphTypes {
  bar = 'bar',
  doughnut = 'doughnut',
  pie = 'pie',
}

export enum ContainerTypes {
  COREPROGRAM = 'Core Program',
  EXTRAS = 'Extras',
}

export const defaultLanguage = 'en_ca';

export const getDefaultAvaliableLanguage = () => {
  return [defaultLanguage];
};
